/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
// Core components
import useContext from '@/client/context';
import { Button, Input } from '@client/components';
import useForm from '@client/components/Form';
// Form
import BookCallForm from '@/common/forms/company/bookCall';

/*----------------------------------
- TYPES
----------------------------------*/

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ class: className, ...props }: React.HTMLAttributes<HTMLFormElement>) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api, toast } = useContext();

    const [wasSent, setSawSent] = React.useState(false);

    const [form, fields] = useForm( BookCallForm, {
        submit: data => api.post('@employers/auth/contact', data)
            .then(() => {
                toast.success("Thank you");
                setSawSent(true);
            })
    });

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <form action="" class={"col " + (className || '')} {...props}>

            {wasSent === false ? <>

                <Input title="First Name" {...fields.firstName} />

                <Input title="Last Name" {...fields.lastName} />

                <Input title="Company Name" {...fields.companyName} />

                <Input title="Email Address" {...fields.email} />

                <Input title="Phone Number" {...fields.phone} />

                <footer class="row al-right">
                    <Button type="primary" shape="pill" 
                        async onClick={() => form.submit()}>
                        Book a call
                    </Button>
                </footer>
            
            </> : <>

                <i src="check-circle" class="xxl fg success" />

                <h2>
                    Talk to you soon!
                </h2>

                <p>
                    Our recruitment experts will contact you back very soon.
                </p>
            
            </>}

        </form>
    )
}