/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import MarqueeComponent from "react-fast-marquee";

// Core
import { Router } from '@app';

// Core components
import { Button, Input } from '@client/components';

// App
import Page from '../_layout/_page'
import PartnersLogos from './PartnersLogos';
import Testimonials from './Testimonials';
import FAQ from '@/client/components/landing/FAQ';

// Advantages images
import OnboardIcon from './steps/onboard.png';
import SourceIcon from './steps/source.png';
import InterviewIcon from './steps/interview.png';
import SelectIcon from './steps/select.png';
// Signup
import HeroIllustration from './illustrations/hero.png';
import SignupUpRocket from './illustrations/contact.png';
// Specific
import BookDemoForm from './BookCallForm';

/*----------------------------------
- TYPES
----------------------------------*/

const figures = {
    headhunters: 2000,
    clients: 100,
    candidates: 750,
}

const Headlines = [
    // Quality
    {
        id: "quality1",
        title: "We hire the most brilliant talents for your team", 
        subtitle: ""
    },
    {
        id: "quality2",
        title: "We will find the next hidden gem for your team",
        subtitle: ""
    },
    // Time
    // Accompaniment
    // General
    {
        id: "general1",
        title: "The Turnkey Solution to Hire the most Brilliant Talents Efficiently.",
        subtitle: ""
    },
    {
        id: "general2",
        title: "Outstanding talent creates great companies",
        subtitle: "We leverage our global network of " + figures.headhunters + "+ professional local headhunters to make your hiring more efficient"
    },
]

const QnA = [{
    question: 'How does our success model works?',
    answer: `The rate is between 15-30% based on the annual salary.`
},{
    question: 'How does Cross Path ensure the quality of its headhunters?',
    answer: `We have conversations with every recruiter who wishes to join our platform to learn more about their background and to provide them with an understanding of our processes and expectations.`
},{
    question: 'How do we make sure our headhunters are performing well?',
    answer: `After the headhunter has been approved, our platform continuously monitors their activity and performance metrics, such as the number of jobs they are working on, the number of CVs they submit for each job, and the progression of their candidates through the interview process. In addition, we collect verified reviews from employers and candidates, which factor into their overall profile score.`
},{
    question: 'If I am presented with the same candidate by two separate recruitment agencies, what is the course of action?',
    answer: `A digital record is created for every candidate submission, including a timestamp. According to our Terms, if a recruitment consultant submits candidates first, they will be considered the owner of that candidate, and any subsequent submission will be deemed a duplicate.`
}]

/*----------------------------------
- CONTROLEUR
----------------------------------*/
Router.page('/', { static: true }, ({ }) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const Marquee = SERVER ? 'div' : MarqueeComponent;

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return  (
        <Page id="companies" headerClass="bg dark" audience="companies"
            title="The premier hiring platform for top-tier recruitment."
            description="Revolutionise your recruitment process with CrossPath - the ultimate headhunter marketplace (hiring platform) for finding exceptional talent. Join us today and see the results!">

            <header class="bg dark swirl-tl" id="page-header">

                <div class="center row sp-4 fill wrap-rev">
                    <div class="col al-left sp-2 w-3-a">

                        <div class="col al-left l txt-left">
                            <h1>
                                The Turnkey Solution to Hire the most Brilliant Talents Efficiently.
                            </h1>

                            <p>
                                We leverage a global network of <strong class="fg c1">{figures.headhunters}+ headhunters</strong>, combined to the latest technologies, to make your recruitment more efficient.
                            </p>
                        </div>

                        <div class="row al-left">
                            <Button shape='pill' type="primary" class="pdh-2" link='#bookDemo'>
                                Book a Call
                            </Button>

                            <Button link="/headhunters" shape='pill' class="pdh-2">
                                I'm a Headhunter
                            </Button>
                        </div>
                    </div>
                    <div class="col w-3-a">
                        <img src={HeroIllustration} />
                    </div>
                </div>

            </header>

            <Marquee pauseOnHover className="bg primary h-1" direction="left">
                <ul class="row l" id="usp-list">
                    <li>{figures.headhunters}+ headhunters</li>      
                    <li>{figures.clients}+ Satisfied clients</li>       
                    <li>5 Days on average to deliver a candidate</li>   
                    <li>{figures.candidates}+ Sourced Talents</li>
                </ul>
            </Marquee>

            <Marquee pauseOnHover className="h-1 client-logos" direction="left" speed={100}>
                <PartnersLogos />
            </Marquee>

            <section class="light-orbs swirl-rb">
                <div class="center col sp-3">

                    <header class="col">
                        <h2>Streamline Your Hiring Process</h2>

                        <p class="txt-center">
                            Accelerate candidate acquisition with our revolutionary Cross Path marketplace technology. 
                        </p>
                        <p class="txt-center">
                            Unlock new talent for your company and teams in just days, maximising growth and efficiency.
                        </p>
                    </header>

                    <ol class="grid xa3 al-fill">
                        <li class="card bg grey col al-center al-top">

                            <img src={OnboardIcon} />

                            <h3>Experience a seamless onboarding</h3>

                            <p class="row-1">
                                ...by sharing your hiring requirements with our team. Choose the success fee model that suits your needs and embark on a transformative journey with Cross Path.
                            </p>

                        </li>
                        <li class="card bg grey col al-center al-top">

                            <img src={SourceIcon} />

                            <h3>Sit back and let our highly qualified headhunters</h3>

                            <p class="row-1">
                                ...source top-tier candidates for you. Within a short span, we'll present you with a curated selection of candidates that align with your needs.
                            </p>

                        </li>
                        <li class="card bg grey col al-center al-top">

                            <img src={InterviewIcon} />

                            <h3>Engage with the most promising candidates</h3>

                            <p class="row-1">
                                ...from our curated pool. Schedule interviews effortlessly, exploring their potential to contribute to your organisation's success.
                            </p>

                        </li>
                        <li class="card bg grey col al-center al-top">

                            <img src={SelectIcon} />

                            <h3>Identify the best fit</h3>

                            <p class="row-1">
                                ...among the talented individuals you've interviewed. Once you've found your ideal candidate, finalise the hiring process by signing the contract with Cross Path. Prepare to welcome a valuable addition to your team
                            </p>

                        </li>
                    </ol>

                    <div class="row al-center l">
                        <Button shape='pill' type="primary" class="pdh-2" link="#bookDemo">
                            Book a Call
                        </Button>

                        <Button type="secondary" shape='pill' class="pdh-2" link="#testimonials">
                            Read Testimonials
                        </Button>
                    </div>

                </div>
            </section>

            <section class="bg dark col al-center sp-3">
                <div class="center grid sp-3 xa3 al-fill">

                    <header class="card col bg primary">
                        <h2>Why choose Cross Path?</h2>
                    </header>

                    <div class="card col al-center al-top">

                        <i src="clock" class="xxl" />

                        <h3>We save your Time</h3>

                        <p>
                            Because we take care of the whole recruitment process, you will only do the final interview with the best profiles we selected.
                        </p>

                    </div>

                    <div class="card col al-center al-top">

                        <i src="stars" class="xxl" />

                        <h3>Quality, or nothing.</h3>

                        <p>
                            All the candidates are triple-checked before to be validated: by Headhunters, by AI and by Cross Path Experts.
                        </p>

                    </div>

                    <div class="card col al-center al-top">

                        <i src="handshake" class="xxl" />

                        <h3>Dedicated Account Executive</h3>

                        <p>
                            For each position you hire, a dedicated Account Executive will answer to your concerns and ansure that everything is going like your expectations. 
                        </p>

                    </div>

                    <div class="card col al-center al-top">

                        <i src="globe" class="xxl" />

                        <h3>Global Network of headhunters</h3>

                        <p>
                            The collective of headhunting experts we built, as well as the high tech productivity tools we provide to them, is able to close any kind of recruitment missions, no mateter the level of difficulty.
                        </p>

                    </div>

                    <div class="card col al-center al-top">

                        <i src="check-double" class="xxl" />

                        <h3>Only pay on success</h3>

                        <p>
                            As a warranty of the quality of our service, you will only be asked to pay the recruitment fees if you're satisfied by the talents we proposed to you.
                        </p>

                    </div>

                </div>
            </section>

            {/* <section class="bg gradient-h">
                <div class="center col">

                    <h2>How Cross Path is so different?</h2>

                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <th>
                                    Cross Path
                                </th>
                                <th>
                                    Agencies
                                </th>
                                <th>
                                    Job Boards
                                </th>
                                <th>
                                    Freelance Headhunters
                                </th>
                                <th>
                                    In-House recruiters
                                </th>
                            </tr>
                            <tr>
                                <th>Very customised </th>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </section> */}

            <section class="bg img col sp-3" style={{
                backgroundImage: 'radial-gradient(circle at right bottom, #ED7EB3, #FEA564)',
            }}>

                <div class="center card bg dark col pd-3">

                    <h2>What we already Accomplished</h2>

                    <ul class="grid xa2 sp-3 al-fill wrap">
                        <li class="card pdv-2 col al-top">

                            <i src="building" class="xl fg gradient" />

                            <strong class="xxl">
                                {figures.clients}+
                            </strong>

                            <div class="col al-center sp-0">
                                <h3>Companies</h3>

                                <p>Trusts Cross Path</p>
                            </div>

                        </li>
                        <li class="card pdv-2 col">

                            <i src="user" class="xl fg gradient" />

                            <strong class="xxl">
                                {figures.candidates}+
                            </strong>

                            <div class="col al-center sp-0">

                                <h3>Talented Profiles</h3>

                                <p>Sourced for our clients</p>
                            </div>

                        </li>
                        <li class="card pdv-2 col">

                            <i src="binoculars" class="xl fg gradient" />

                            <strong class="xxl">
                                {figures.headhunters}+
                            </strong>

                            <div class="col al-center sp-0">

                                <h3>Headhunters</h3>

                                <p>Around the world</p>
                            </div>

                        </li>
                    </ul>
                </div>

                <div class="col center al-center">
                    <p class="xxl txt-center w-a-7">
                        Are you curious to see how Cross Path will supercharge the recruitment in your company?
                    </p>

                    <Button class="bg dark l" shape="pill" link="#bookDemo">Book a Demo</Button>
                </div>

            </section>

            <footer class="bg grey col pdv-3 sp-3">

                <section id="testimonials" class="col pdh-0">

                    <h2>What our Client Says about Us</h2>

                    <Marquee pauseOnHover>
                        <Testimonials />
                    </Marquee>

                </section>

                <section id="bookDemo" class="center card bg dark pdv-4 swirl-lb row">

                    <div class="row fill wrap-rev w-a-8 sp-3">
                        <header class="col al-left w-3-a">
                            <h2>Get started with us today!</h2>

                            <img src={SignupUpRocket} />
                        </header>

                        <BookDemoForm class="w-3-a" />
                    </div>

                </section>

                <section class="pdh-1">
                    <div class="center col al-center">

                        <h2>Do you have any questions?</h2>

                        <FAQ QnA={QnA} />

                    </div>
                </section>

            </footer>

        </Page>
    )
});