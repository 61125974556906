/*----------------------------------
- DEPS
----------------------------------*/

import React from 'react';

import { Link } from '@client/components';
/*----------------------------------
- COMPONENT
----------------------------------*/

const Logos = () => <>
    <li><Link to="https://www.linkedin.com/company/altamodaparis/about/">
        <img src="/public/partners/altamoda.webp" />
    </Link></li>
    <li><Link to="https://citron.io">
        <img src="/public/partners/citron.webp" />
    </Link></li>
    <li><Link to="https://www.getclone.io/">
        <img src="/public/partners/clone.webp" />
    </Link></li>
    <li><Link to="https://www.cathaycapital.com/">
        <img src="/public/partners/cathayCapital.webp" />
    </Link></li>
    <li><Link to="https://www.graham-allen.com/">
        <img src="/public/partners/graham-allen.webp" />
    </Link></li>
    <li><Link to="https://www.hyperplan.fr/">
        <img src="/public/partners/hyperplan.webp" />
    </Link></li>
    <li><Link to="https://www.pyratzlabs.com/">
        <img src="/public/partners/pyratzlabs.webp" />
    </Link></li>
    <li><Link to="https://qumin.co.uk/">
        <img src="/public/partners/qumin.webp" />
    </Link></li>
    <li><Link to="https://revibe.me">
        <img src="/public/partners/revibe.webp" />
    </Link></li>
    <li><Link to="https://www.staytouch.com/">
        <img src="/public/partners/stayTouch.webp" />
    </Link></li>
    <li><Link to="https://nested.com/">
        <img src="/public/partners/nested.webp" />
    </Link></li>
    <li><Link to="https://www.systemanova.vc/">
        <img src="/public/partners/systema.webp" />
    </Link></li>
    <li><Link to="https://techmind.vc/">
        <img src="/public/partners/techmind.png" />
    </Link></li>
    <li><Link to="https://urbest.io/en/">
        <img src="/public/partners/urbest.webp" />
    </Link></li>
</>

export default () => {
    return (
        <ul class="row sp-4">
            <Logos />
        </ul>
    )
}