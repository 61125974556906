/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Core
import { Schema, Validators } from '@common/validation';

const field = new Validators();

/*----------------------------------
- SCHEMA
----------------------------------*/
export default new Schema({

    firstName: field.string(),

    lastName: field.string(),

    companyName: field.string(),

    email: field.email(),

    phone: field.string(),

});