/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core

// App
import Testimonial from '@/client/components/landing/Testimonial';

// Resources
import RudyKadoch from './photos/rudy.kadoch.jpg';
import OlivierTilloy from './photos/olivier.tilloy.jpg';
import LouisLimnavong from './photos/louis.limnavong.jpg';
import HugoGervais from './photos/hugo.gervais.jpg';
import StephaneMardel from './photos/stephane.mardel.jpg';
import RemiBanquet from './photos/remi.banquet.jpg'

/*----------------------------------
- DEPENDANCES
----------------------------------*/
export default () => <>
    <div class="testimonials row al-left sp-2 x-4 y-2 al-top mgl-2">
        <Testimonial 
            className="y1-3"
            person="Rudy Kadoch" 
            description={<>
                CEO at <a href="https://nested.fi/" target="_blank">Nested</a>
            </>} 
            photo={RudyKadoch}
            logo={"/public/partners/nested.webp"}
            text="I am extremely pleased with the services provided by Cross Path in assisting with our recruitment needs at Nested. As our company began to grow rapidly, we required additional staff and Cross Path provided an innovative approach to the hiring process. With their expert assistance, we were able to find exceptional candidates with ease, without any stress.From the moment we shared our requirements with them, Cross Path took charge of the entire process and efficiently sourced the most suitable candidates within a short period of time. Their exceptional speed, efficiency and quality of service made the entire recruitment process seamless and stress-free. Thanks to Cross Path's expertise, we were able to hire two outstanding candidates quickly, which was a huge relief for us. The entire experience was first-class and I am confident in using Cross Path's services again in the future"
        />
        <Testimonial 
            person="Olivier Tilloy" 
            description={<>
                Managing Partner at <a href="https://techmind.vc/en" target="_blank">Techmind</a>
            </>}
            photo={OlivierTilloy}
            logo={"/public/partners/techmind.png"}
            text="Sourcing candidates has never been easier. What i like about Cross Path is that they have experts who are specialised in a specific field and no more need to have agencies who often lie about their area of expertises which saves us a lot of time!"
        />
        <Testimonial 
            person="Stephane Mardel" 
            description={<>
                Founder at <a href="https://systemanova.vc/" target="_blank">SystemaNova</a>
            </>}
            photo={StephaneMardel}
            logo={"/public/partners/systema.webp"}
            text="Cross Path has given me the opportunity to hire the best talent for my business. Cross Path takes cares of all the work suring the process, which saves my team and me a lot of time! I highly recommand Cross Path as an extremely valuable recruitment tool!"
        />
        <Testimonial 
            person="Hugo Gervais" 
            description={<>
                Founder at <a href="https://urbest.io/fr/" target="_blank">Urbest</a>
            </>}
            photo={HugoGervais}
            logo={"/public/partners/urbest.webp"}
            text="With Cross Path, I can Blindly trust the quality of candidates. I got excellent business developers in three days"
        />
        <Testimonial 
            person="Louis Limnavong" 
            description={<>
                General Manager at <a href="https://www.getclone.io/" target="_blank">Clone</a>
            </>}
            photo={LouisLimnavong}
            logo={"/public/partners/clone.webp"}
            text="After our fundraising, we were actively looking for very specific profiles in a niche sector. Cross path was able to present us with twenty qualified candidates faster than our usual recruitment agencies, in a couple of days thanks to their talent pool and their tools. I highly recommend Cross Path to all companies!"
        />
        <Testimonial 
            className="y1-3"
            person="Remi Banquet" 
            description={<>
                Director of Business at <a href="https://www.hyperplan.fr/" target="_blank">HyperPlan</a>
            </>}
            photo={RemiBanquet}
            logo={"/public/partners/hyperplan.webp"}
            text="As I joined the team I had to build a team of sales managers and initially used LinkedIn to recruit. However, I received a large number of applications and found it challenging to find the right profiles amidst their operational tasks. So I switched to using Cross Path, which only took half an hour to brief on the desired profiles and search across different countries. Cross Path provided qualified profiles, and the recruitment process was smooth. As a startup with many tasks, having support on the HR and recruitment side was extremely helpful. Within short time we hired international qualified candidates that and ready to work from day one."
        />

    </div>
</>